import React from 'react'
import Layout from '../components/Layout'

export default function Error404() {
    return (
        <Layout>
            <div className="flex flex-col flex-1 items-center justify-content-center mt-10">
                <div className="font-open-sans font-medium text-xl text-center text-ash-bright">
                    Page Not Found
                </div>
            </div>
        </Layout>
    )
}
